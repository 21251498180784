import React from "react";
import { Col, Result, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";


export const InitializingApp = () => {

  const { t } = useTranslation('common');

  return (
    <Row justify="center" align="middle" style={{ width: '100vw', height: '100vh' }}>
      <Col span={24} align="center">
        <Result
          icon={<Spin />}
          title={t('initializing')} />
      </Col>
    </Row>
  );
}
import React, { useEffect, useState } from 'react';
import { Layout, Menu, Row, Avatar, Col, Space, Button, Tooltip } from 'antd';
import { keycloak } from '../configs/keycloak';
import logo from '../assets/logo.png';
import { useKeycloak } from '@react-keycloak/web';
import { decodeJwt } from 'jose';
import './layoutStyle.css';
import { BaseNavbar } from './BaseNavbar';
import { Roles } from '../router/Protected';
const { Header } = Layout;
export const MainHeader = () => {
  const [navItems, setNavitems] = useState([]);
  const { keycloak: { token } } = useKeycloak();
  const { name, realm_access } = decodeJwt(token);
  useEffect(() => {
    if (realm_access?.roles?.includes(Roles.SUPERADMIN)) {
      setNavitems(BaseNavbar);
      return;
    }
    if (realm_access?.roles && realm_access?.roles.length > 0) {
      const securedNavbar = filterSecureNav(BaseNavbar);
      setNavitems(securedNavbar);
    }
  }, [])
  const filterSecureNav = items => {
    const filteredItems = [];
    for (const item of items) {
      if (item.roles && !item.roles.some(role => realm_access.roles.includes(role))) continue;
      if (!item.children) {
        filteredItems.push(item);
        continue;
      }
      const filteredChildren = filterSecureNav(item.children);
      filteredItems.push({ ...item, children: filteredChildren });
    }
    return filteredItems;
  }
  return (
    <Header
      style={{
        backgroundColor: 'white',
        fontSize: 12,
        color: '#5847B2',
        fontWeight: 'bold',
        boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.2)",
        position: 'fixed',
        zIndex: 10,
        width: '100%',
      }}>
      {process.env.REACT_APP_ENVIRONMENT === 'staging' && <div className='testing-badge'>STAGING</div>}
      <Row>
        <Col span={20}>
          <Menu
            mode="horizontal"
            items={navItems}
            selectedKeys={[window.location.pathname]} />
        </Col>
        <Col span={4} align="end">
          <Space align='center'>
            <Tooltip title="Click to log out">
              <Button
                type='ghost'
                onClick={() => keycloak.logout()}>
                  <Avatar size={16} src={logo} style={{ marginRight: '10px' }} /> {name}
              </Button>
            </Tooltip>
          </Space>
        </Col>
      </Row>
    </Header>
  );
}
import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { AppLayout } from '../layout/Layout';
import { NotFoundPage } from '../pages/notFound/NotFoundPage';

const CategoryScreen = lazy(() => import('../pages/category/CategoryScreen'));
const BrandScreen = lazy(() => import('../pages/brand/BrandScreen'));
const AssetScreen = lazy(() => import('../pages/asset/AssetScreen'));
const TypePropertyScreen = lazy(() => import('../pages/typeProperty/TypePropertyScreen'));
const AssignmentScreen = lazy(() => import('../pages/assignment/AssignmentScreen'));
const SupplierScreen = lazy(() => import('../pages/supplier/SupplierScreen'));
const AssetTypeScreen = lazy(() => import('../pages/assetType/AssetTypeScreen'))
const HomeScreen = lazy(() => import('../pages/home/HomeScreen'));


const routesArray = [
  {
    path: '/',
    element: <HomeScreen />
  },
  {
    path: '*',
    element: <NotFoundPage />
  },
  {
    path:'assignment',
    element: <AssignmentScreen/>
  },
  {
    path: 'assets/*',
    element: <AssetScreen/>
  },
  {
    path:'asset-types/*',
    element: <AssetTypeScreen/>,
  },
  {
    path:'asset-type-properties',
    element: <TypePropertyScreen/>  
  },
  {
    path:'supplier',
    element: <SupplierScreen/>
  },
  {
    path:'asset-category',
    element: <CategoryScreen/>
  },
  {
    path:'asset-brand/*',
    element: <BrandScreen/>
  },
];

const AppRoutes = () => {
  const routes = useRoutes(routesArray);
  return routes;
}

export const Router = () =>
  <BrowserRouter basename={`${process.env.REACT_APP_PUBLIC_URL}`}>
    <AppLayout>
      <Suspense fallback={<Spin spinning />}><AppRoutes /></Suspense>
    </AppLayout>
  </BrowserRouter>;
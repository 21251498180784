import { log } from "./logger";


export const updateStorageItem = (key, value) => {
  try {
    localStorage.setItem(`${key}`, value);
  } catch (error) {
    log(`localstorage error during ${key} store:`, error);
  }
}

export const getStorageItem = (key) => {
  try {
    return localStorage.getItem(`${key}`);
  } catch (error) {
    log(`localstorage error during get ${key}:`, error);
  }
}

export const clearStorageItem = (key) => {
  try {
    localStorage.removeItem(`${key}`);
  } catch (error) {
    log(`localstorage error during remove ${key}:`, error);
  }
}

export const clearStorage = () => {
  localStorage.clear();
}
import React from 'react';
import { MainHeader } from './MainHeader';
import { Layout } from 'antd';


const { Content, Footer } = Layout;

export const AppLayout = ({ children }) =>
  <Layout style={{ height: '100%', textAlign: 'center' }}>
    <MainHeader />
    <Content style={{ marginTop: '67px', padding: '16px', minHeight: 'calc(100vh - 150px)', display: 'flex' }}>
      {children}
    </Content>
    <Footer>ExoMindset ©2023</Footer>
  </Layout>
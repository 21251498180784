import React, { StrictMode, Suspense } from 'react';
import { Router } from './router/Router';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloak } from './configs/keycloak';
import { InitializingApp } from './components/InitializingApp';
import { updateStorageItem } from './helpers/local-storage';
import { QueryClient, QueryClientProvider } from "react-query";
import './App.css';

const queryClient = new QueryClient();

const App = () => {

  const onKeycloakEvent = event => {
    if (!keycloak.authenticated) keycloak.login();
    if (event === "onAuthError"
      || event === "onAuthRefreshError"
      || event === "onAuthLogout") keycloak.login();
  };

  const onKeycloakTokens = tokens => {
    if (tokens?.token) updateStorageItem("KC_TOKEN", JSON.stringify(tokens));
    else keycloak.login();
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{ onload: 'login-required' }}
        onEvent={onKeycloakEvent}
        onTokens={onKeycloakTokens}
        LoadingComponent={<InitializingApp />}>
        {/* <StrictMode> */}
          <Suspense fallback={<InitializingApp />}>
            <Router />
          </Suspense>
        {/* </StrictMode> */}
      </ReactKeycloakProvider>
    </QueryClientProvider>
  );
}

export default App;
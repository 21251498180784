import { Link } from 'react-router-dom';
import { Roles } from '../router/Protected';


export const BaseNavbar = [
  {
    key: 'ASSETS',
    label: (<Link to={'assets'} className='nav-link' >Assets</Link>),
  },
  {
    key: 'ASSIGNMENTS',
    label: (<Link to={'assignment'} className='nav-link' >Assignment</Link>)
  },
  {
    key: 'TYPES',
    label:'Type',
    children: [
      {
        key: "ASSET-TYPE",
        label: (<Link to={'asset-types'} className='nav-link' >Types</Link>),
      },
      {
        key: 'TYPE-PROPERTY',
        label: <Link className='nav-link' to={'asset-type-properties'} >All Properties</Link>
      },
      {
        key: 'CATEGORY',
        label: <Link className='nav-link' to={'asset-category'}>Categories</Link>
      },
    ]
  },
  {
    key: 'SUPPLIERS',
    label: <Link className='nav-link' to={'supplier'}>Suppliers</Link>,
  },
  {
    key: 'BRANDS',
    label: <Link className='nav-link' to={'asset-brand'}>Brands</Link>
  }
]